import React, { useEffect } from "react";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import Sales from "./salesAndpurchase/sales";
import "./salesAndpurchase/salesAndPurchase.scss";
import PurcahseTab from "./salesAndpurchase/purchaseTab";
import { fetchUserMasterData } from "./state/slices/mainSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./state/store";
import DashboardHeader from "./header/header";
function Invoices() {

  const dispatch = useDispatch<AppDispatch>();
  const orgId: string = localStorage.getItem("orgId")!;
  const sk = `items#${orgId.slice(-5)}`;
  console.log(sk, "sk");

  useEffect(() => {
    dispatch(fetchUserMasterData({ id: orgId, sk }));
  }, [dispatch]);
  
  return (
    <>
    <DashboardHeader/>
    <Tabs
      defaultActiveKey="1"
      type="card"
      // style={{
      //   position: "absolute",
      //   top: "150px",
      // //   backgroundColor: "yellow",
      //   width:'100vw',
      //   padding:'20px'
      // }}
      className="salesAndPurchase"
    >
      <TabPane tab="Sales" key="1">
        <Sales />
      </TabPane>
      <TabPane tab="Purchase" key="2">
        <PurcahseTab />
      </TabPane>
      {/* <TabPane tab="Inventory Report" key="3">
      <InventoryTable />
    </TabPane> */}
 
    </Tabs>

    </>
  );
}

export default Invoices;
