// export const BASE_URL = process.env.REACT_APP_BASE_URL;
// export const STAGE = process.env.REACT_APP_ENV;
import axios from "axios";
import { updateMasterList } from "../slices/mainSlice";
import { searchFields } from "../../reports/stockSummary";

export const BASE_URL = `https://enzdzh0pw2.execute-api.ap-south-1.amazonaws.com`
export const updateOrgProfile = async (values: any) => {
  const updateOrgProfileApi = `${BASE_URL}/dev/orgDetails`;
  // const updateOrgProfileApi = `http://localhost:4000/dev/orgDetails`;
  try {
    const response = await fetch(updateOrgProfileApi, {
      method: "POST",
      body: JSON.stringify(values),
    });
    const result = await response.json();
    console.log(result, "result of updateOrgProfile");
    return result;
  } catch (error) {
    return console.log(error, "error in updateOrgProfile");
  }
};
export const getOrgDetailsFun = async (id: string) => {
  const getOrgDetailsApi = `${BASE_URL}/dev/getOrgDetails?orgId=${encodeURIComponent(
    id
  )}`;
  // const getOrgDetailsApi = `http://localhost:4000/dev/getOrgDetails?orgId=${encodeURIComponent(
  //   id
  // )}`;

  try {
    const response = await fetch(getOrgDetailsApi, {
      method: "GET",
    });
    const result = await response.json();
    console.log(result, "result of getOrgDetailsFun");
    return result;
  } catch (error) {
    return console.log(error, "error in getOrgDetailsFun");
  }
};

export const addUserProfileFun = async (values: any) => {
  const addUserProfileApi = `${BASE_URL}/dev/userDetails`;
  // const addUserProfileApi = `http://localhost:4000/dev/userDetails`;

  try {
    const response = await fetch(addUserProfileApi, {
      method: "POST",
      body: JSON.stringify(values),
    });
    const result = await response.json();
    console.log(result, "result of addUserProfileFun");
    return result;
  } catch (error) {
    return console.log(error, "error in addUserProfileFun");
  }
};

export const getUserProfileFun = async (id: any) => {
  const getUserProfileFunApi = `${BASE_URL}/dev/getUserDetails?orgId=${encodeURIComponent(
    id
  )}`;
  // const getUserProfileFunApi = `http://localhost:4000/dev/getUserDetails?orgId=${encodeURIComponent(
  //   id
  // )}`;

  try {
    const response = await fetch(getUserProfileFunApi, {
      method: "GET",
    });
    const result = await response.json();
    console.log(result, "result of getOrgDetailsFun");
    return result;
  } catch (error) {
    return console.log(error, "error in getOrgDetailsFun");
  }
};

export const getMasterListDataApi = async () => {
  const masterListApi = `https://enzdzh0pw2.execute-api.ap-south-1.amazonaws.com/dev/admin/getMasterListData`;
  // const masterListApi = `http://localhost:4000/${STAGE}/admin/getMasterListData`;
  try {
    const response = await fetch(masterListApi);
    const result = await response.json();
    console.log(result, "result of masterList");
    return result;
  } catch (error) {
    return console.log(error, "error in masterList");
  }
};

export const getMasterListData = () => {
  return async (dispatch: any) => {
    const res = await getMasterListDataApi();
    dispatch(updateMasterList(res));
  };
};

export const fetchUserMasterDataFun = async (id: any,sk:any) => {
  const getUserProfileFunApi = `${BASE_URL}/dev/getUserMasterItems?orgId=${encodeURIComponent(id)}&sk=${encodeURIComponent(sk)}`;
  // const getUserProfileFunApi = `http://localhost:4000/dev/getUserMasterItems?orgId=${encodeURIComponent(id)}&sk=${encodeURIComponent(sk)}`;


  try {
    const response = await fetch(getUserProfileFunApi, {
      method: "GET",
    });
    const result = await response.json();
    console.log(result, "result of fetchUserMasterDataFun");
    return result;
  } catch (error) {
    return console.log(error, "error in fetchUserMasterDataFun");
  }
};

export const saveUserMasterDataFun = async (values: any) => {
  const addUserProfileApi = `${BASE_URL}/dev/saveUserMasterItems`;
  // const addUserProfileApi = `http://localhost:4000/dev/saveUserMasterItems`;

  try {
    const response = await fetch(addUserProfileApi, {
      method: "POST",
      body: JSON.stringify(values),
    });
    const result = await response.json();
    console.log(result, "result of saveUserMasterDataFun");
    return result;
  } catch (error) {
    return console.log(error, "error in saveUserMasterDataFun");
  }
};

export const saveUpdateInvoices = (payLoad: any) => async (dispatch: any) => {
  const invoiceUpdateApi = `${BASE_URL}/dev/updateInvoices`;
  // const invoiceUpdateApi = `http://localhost:4000/dev/updateInvoices`;
  const invoiceUpdateBody = payLoad;
  try {
    const response = await fetch(invoiceUpdateApi, {
      method: "POST",
      body: JSON.stringify(invoiceUpdateBody),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.json();
    console.log(result, "result");
    return result;
  } catch (error) {
    console.error("error", error);
    throw error; // Re-throw the error so that it can be caught in the main code
  }
};

export const saveBillOfSupply =
  (payLoad: any, setUserSk: Function) => async (dispatch: any) => {
    const billOfSupplyApi = `${BASE_URL}/dev/billOfsupply`;
    // const billOfSupplyApi = `http://localhost:4000/dev/billOfsupply`;
    const billOfsupplyBody = payLoad;
    try {
      const response = await fetch(billOfSupplyApi, {
        method: "POST",
        body: JSON.stringify(billOfsupplyBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setUserSk(result);
      console.log(result, "result");
      return result;
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error so that it can be caught in the main code
    }
  };

  export const saveSupplierOrder =
  (payLoad: any, setUserSk: Function) => async () => {
    const supplierOrderApi = `${BASE_URL}/dev/supplierOrder`;
    // const supplierOrderApi = `http://localhost:4000/dev/supplierOrder`;
    const supplierOrderBody = payLoad;
    try {
      const response = await fetch(supplierOrderApi, {
        method: "POST",
        body: JSON.stringify(supplierOrderBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setUserSk(result);
      console.log(result, "result");
      return result;
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error so that it can be caught in the main code
    }
  };

  export const fetchUserDetails = async (endpoint: string) => {
    console.log(endpoint,'endpoint');
    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error("Error fetching user details:", error);
      throw error;
    }
  };

  export const saveGrnEntryData =
  (payLoad: any, setPdfData: Function) => async () => {
    const grnEntryApi = `${BASE_URL}/dev/grn`;
    // const grnEntryApi = `http://localhost:4000/dev/grn`;
    const grnEntryBody = payLoad;
    try {
      const response = await fetch(grnEntryApi, {
        method: "POST",
        body: JSON.stringify(grnEntryBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result, "result");
      setPdfData(result);
      return result;
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error so that it can be caught in the main code
    }
  };

  export const savePurchaseEntryData = (payLoad: any) => async () => {
    const grnEntryApi = `${BASE_URL}/dev/savePurchaseEntryData`;
    // const grnEntryApi = `http://localhost:4000/dev/savePurchaseEntryData`;
    const grnEntryBody = payLoad;
    try {
      const response = await fetch(grnEntryApi, {
        method: "POST",
        body: JSON.stringify(grnEntryBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result, "result");
      return result;
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error so that it can be caught in the main code
    }
  };

  export const savePurchaseReturn =
  (payLoad: any, setPdfData: Function) => async (dispatch: any) => {
    const purchaseReturnApi = `${BASE_URL}/dev/savePurchaseReturn`;
    // const purchaseReturnApi = `http://localhost:4000/dev/savePurchaseReturn`;
    const purchaseReturnBody = payLoad;
    try {
      const response = await fetch(purchaseReturnApi, {
        method: "POST",
        body: JSON.stringify(purchaseReturnBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result, "result");
      setPdfData(result);
      return result;
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error so that it can be caught in the main code
    }
  };

  export const saveSalesReturnData =
  (payLoad: any, setUserSk: Function) => async (dispatch: any) => {
    const invoiceUpdateApi = `${BASE_URL}/dev/saveSalesReturnData`;
    // const invoiceUpdateApi = `http://localhost:4000/dev/saveSalesReturnData`;
    const invoiceUpdateBody = payLoad;
    try {
      const response = await fetch(invoiceUpdateApi, {
        method: "POST",
        body: JSON.stringify(invoiceUpdateBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result.sk,'result.sk')
      setUserSk(result.sk);
      // console.log(result, "result");
      return result;
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error so that it can be caught in the main code
    }
  };

  export const searchItemsFromDb =
  (payLoad: searchFields, setFetchTableData: Function) =>
  async (dispatch: any) => {
    console.log(payLoad, "payload inside api");
    const { dateFromTo, itemName } = payLoad;
    const orgId: string = localStorage.getItem("orgId")!;
    const invoiceUpdateApi = `${BASE_URL}/dev/fetchInventoryTable?itemName=${itemName}&dateFromTo=${dateFromTo}&orgId=${orgId}`;
    // const invoiceUpdateApi = `http://localhost:4000/dev/fetchInventoryTable?itemName=${itemName}&dateFromTo=${dateFromTo}&orgId=${orgId}`;
    try {
      const response = await fetch(invoiceUpdateApi, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result, "result");
      setFetchTableData(result);
      return result;
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error so that it can be caught in the main code
    }
  };