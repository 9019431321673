import React, { useState } from "react";

import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Table,
  Modal,
  Select,
  InputNumber,
  Radio
} from "antd";
// import SearchDocComponent from "../searchDocComponent";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
// import { handlePaymentPdf } from "./generatePaymentPdf";
import dayjs from "dayjs";
import SearchDocComponent from "../searchDocComponent";
// import { RootState } from "../../../../store";
// import { updateSalesPayment } from "../../../../store/api";
const { Option } = Select;
interface PropsItem {
  taxInvoice: boolean;
}

interface ItemType {
  pk: string;
  itemName: string;
  sk: string;
  Amount: number;
  Category: string;
  Grade: string;
  grossAmount: number;
  GST: string;
  GSTAmount: number;
  HSN_Code: string;
  id: string;
  Item_Code: number;
  Produce: string;
  quantity: number;
  quantityInBags: string;
  rate: string;
  Source_Area: string;
  Uom: string;
  Variety: string;
}
const PaymentReceipt = (props: PropsItem) => {
  const { taxInvoice } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [pdfData, setPdfData] = useState("");
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    address: "",
    PoDate: "",
    zip: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
    name: "",
    taxInvoice: "",
    total: 0,
    remainingAmount: 0,
  });
  const [isSearch, setIsSearch] = useState(false);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     console.log(userFormValues, "before set");

//     form.setFieldsValue(userFormValues);
//     console.log(userFormValues, "after set");
//   }, [userFormValues]);

  const saveAllDetails = async (values: any) => {
    console.log(userFormValues.customerCode, "customerCode");
    console.log(userFormValues.zip, "zip");
    console.log(values, "values");
   const remainingAmount = userFormValues.remainingAmount
      ? userFormValues.remainingAmount - values.amountPaid
      : userFormValues.total - values.amountPaid;
      console.log(userFormValues.remainingAmount,'userFormValues.remainingAmount');
      console.log(userFormValues.total,'userFormValues.total')
    console.log(remainingAmount, "remainingAmount");
    const payLoad = {
      ...values,
      ...userFormValues,
      total: userFormValues.total,
      remainingAmount,
    };
    console.log(payLoad, "payLoad");
    // dispatch(updateSalesPayment(payLoad));
    form.resetFields();
    setPdfData(payLoad);
    setIsSearch(false);
  };
  const printPdf=()=>{
    console.log("im here pdf items",pdfData);
    // handlePaymentPdf(pdfData);
    form.resetFields();
  }
  const handleUserCancel = () => {
      setIsUserModalVisible(false);
  };
  console.log(pdfData,"inside payment");
  return (
    <div className="paymentReceipt">
      <SearchDocComponent
        docType={"BOSTI"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form layout="horizontal" onFinish={saveAllDetails} form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                { required: true, message: "Please enter the Phone Number!" },
              ]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Sales Invoice No"
              name="sk"
              rules={[
                {
                  required: true,
                  message: "Please enter the Sales Invoice No!",
                },
              ]}
            >
              <Input placeholder="Enter Sales Invoice No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Date"
              name="PaymentDate"
              rules={[{ required: true, message: "Please select the Date!" }]}
            >
              <DatePicker/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Sales Invoice Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select the Sales Invoice Date!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="PO No"
              name="PoNo"
              rules={[{ required: true, message: "Please enter the PO No!" }]}
            >
              <Input placeholder="Enter PO No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Name"
              name="name"
              rules={[
                { required: true, message: "Please enter the Supplier Name!" },
              ]}
            >
              <Input placeholder="Enter Supplier Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Amount Paid"
              name="amountPaid"
              rules={[
                { required: true, message: "Please enter the Amount" },
              ]}
            >
              <InputNumber placeholder="Enter Amount Paid " />
            </Form.Item>
            <Button
             onClick={() => {
            setIsUserModalVisible(true);
            }}
            >
            Add User
          </Button>
          <Modal
            title={"Add User"}
            open={isUserModalVisible}
            onCancel={handleUserCancel}
            footer={null}
            width={400}
            >
              <Form
        form={form}
        layout="horizontal"
        // onFinish={handleSubmit}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter your name' }]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item
        label="User Type"
        name="userType"
        rules={[{ required: true, message: 'Please select user type' }]}
        >
         <Radio.Group>
         <Radio value="customer">Customer</Radio>
         <Radio value="supplier">Supplier</Radio>
        </Radio.Group>
        </Form.Item>
        <Form.Item
          label="gstin"
          name="gstin"
          rules={[{ required: true, message: 'Please enter GESTIN' }]}
        >
          <Input placeholder="Enter Gstin" />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: 'Please enter phone number' }]}
        >
          <Input placeholder="Enter phone number" />
        </Form.Item>

        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: 'Please enter address' }]}
        >
          <Input placeholder="Enter address" />
        </Form.Item>

        <Form.Item
          label="Delivery Address"
          name="delivery_address"
          rules={[{ required: true, message: 'Please enter delivery address' }]}
        >
          <Input placeholder="Enter delivery address" />
        </Form.Item>
        <Form.Item
          label="Pincode"
          name="pincode"
          rules={[{ required: true, message: 'Please enter pincode' }]}
        >
          <Input placeholder="Enter pincode" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit"  block  style={{ width: '150px', display: 'block', margin: '0 auto' }}>
            Submit
          </Button>
          </Form.Item>
          </Form>
          </Modal>
          </Col>
        </Row>
        <div className="totalamount">
          <strong>Total Amount:{userFormValues?.total}</strong>
          <strong>
            Remaining Amount:
            {userFormValues.remainingAmount || userFormValues?.total}
          </strong>
        </div>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        <Button onClick={printPdf} style={{ width: '150px' }}>Download Receipt</Button>
      </Form>
    </div>
  );
};
export default PaymentReceipt;


