import React, { useEffect, useState } from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { fetchUserAttributes, signUp } from "@aws-amplify/auth";
import { useDispatch } from "react-redux";
import { getOrgDetails, orgloginDetails, User } from "./state/slices/mainSlice";
import { AppDispatch } from "./state/store";
import { getOrgDetailsFun } from "./state/api";

const AuthenticatorPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { user, signOut } = useAuthenticator(); // useAuthenticator hook provides user info
  const [isLoading, setIsLoading] = useState(true);
  const services = {
    async handleSignUp(input) {
      // Custom username and email
      const { password, options } = input;
      const customEmail = options?.userAttributes?.email.toLowerCase();
      return signUp({
        username: customEmail,
        password,
        options: {
          ...input.options,
          userAttributes: {
            email: customEmail,
            "custom:is_New_Client": "yesIam",
          },
        },
      });
    },
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user) {
          const userAttributes: any = await fetchUserAttributes();
          const isNewClient = userAttributes["custom:is_New_Client"];
          // dispatch(orgloginDetails(userAttributes));
          localStorage.setItem("orgId", JSON.stringify(userAttributes.sub));
          localStorage.setItem(
            "orgEmail",
            JSON.stringify(userAttributes.email)
          );
          if (isNewClient === "yesIam") {
            navigate("/profile");
          } else {
            navigate("/dashboard");
          }
        }
      } catch (error) {
        console.error("Error fetching user attributes:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchUserData();
    } else {
      setIsLoading(false); // Stop loading if no user
    }
  }, [user, navigate, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>; // Show loading message while processing
  }

  return (
    <Authenticator services={services} initialState="signUp">
      {({ signOut }) => (
        <>
          {/* Your component logic or additional UI here */}
          {/* Display if necessary */}
        </>
      )}
    </Authenticator>
  );
};

export default AuthenticatorPage;

// import React, { useEffect } from "react";
// import { Authenticator } from "@aws-amplify/ui-react";
// import { useNavigate } from "react-router-dom";
// import { signUp, fetchUserAttributes } from "@aws-amplify/auth";
// import { useDispatch } from "react-redux";
// import { AppDispatch } from "./state/store";
// import { getOrgDetails, orgloginDetails, User } from "./state/slices/mainSlice";

// const AuthenticatorPage = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch<AppDispatch>();
//   const services = {
//     async handleSignUp(input) {
//       // custom username and email
//       const { password, options } = input;
//       const customEmail = options?.userAttributes?.email.toLowerCase();
//       return signUp({
//         username: customEmail,
//         password,
//         options: {
//           ...input.options,
//           userAttributes: {
//             email: customEmail,
//             "custom:is_New_Client": "yesIam",
//           },
//         },
//       });
//     },
//   };

//   useEffect(() => {
//     const fetchUserAttributesMy = async () => {
//       try {
//         const user = await fetchUserAttributes();
//         console.log(user, "user");
//         localStorage.setItem("orgId", JSON.stringify(user.sub));
//         localStorage.setItem("orgEmail", JSON.stringify(user.email));
//         const isNewClient = user["custom:is_New_Client"];
//         console.log(isNewClient, "isnew client");
//         const sub: string = user.sub!;
//         if (isNewClient === "yesIam") {
//           navigate("/profile");
//         } else {
//           dispatch(getOrgDetails(sub));
//           navigate("/dashboard");
//         }
//       } catch (error) {
//         console.log("No user is signed in", error);
//       }
//     };

//     fetchUserAttributesMy();
//   }, [navigate]);

//   return (
//     <Authenticator services={services} initialState="signUp">
//       {({ signOut }) => (
//        <></>
//       )}
//     </Authenticator>
//   );
// };

// export default AuthenticatorPage;
