import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Modal,
  Table,
  Select,
  message,
  Popconfirm,
  Row,
  Col,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import SearchDocComponent from "../searchDocComponent";
// import SearchComponent from "../searchComponent";
import { AppDispatch, RootState } from "../../state/store";
import { addItem, deleteItem } from "../../state/slices/mainSlice";
import { saveSupplierOrder, saveUpdateInvoices } from "../../state/api";
const { Option } = Select;

interface ItemType {
  key: string;
  name: string;
  category: string;
  variety: string;
  grade: string;
  gst: string;
  hsncode: string;
  uom: string;
}

interface Item {
  id: number;
  slno: number;
  Item_Code: string;
  itemName: string;
  quantity: number;
  rate: number;
  Uom: string;
  Amount: number;
  GST: string;
  GSTAmount: number;
  grossAmount: number;
}

interface MasterItem {
  pk: string;
  Item_Code: string;
  itemName: string;
  GST: number;
}
const SupplierOrder: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [userSk, setUserSk] = useState("");
  const [userDetails, setUserDetails] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    PoDate: "",
    pincode: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
  });
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [isSearch, setIsSearch] = useState(false);
  const [pdfData, setPdfData]: any = useState("");
  const userMasterList = useSelector(
    (state: RootState) => state.main.userMasterListData
  );
  const ItemListData = useSelector((state: RootState) => state.main.itemList);
  const customersDetails = useSelector(
    (state: RootState) => state.main.userProfileDetails.suppliers
  );
  useEffect(() => {
    // Update form values whenever the form fields change
    form.setFieldsValue(userDetails);
  }, [userDetails]);

  // useEffect(() => {
  //   console.log(userDetails, "userFormValues inside useEffect");
  //   userDetails.ItemListData.forEach((item, index) => {
  //     dispatch(addItem(item));
  //   });
  // }, [isSearch]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleMasterCancel = () => {
    setIsUserModalVisible(false);
  };

  const handleModalFormSubmit = () => {
    modalForm.validateFields().then((values) => {
      console.log("Modal Form Values:", values);
      const itemFromList = userMasterList.find(
        (item: ItemType) => item.name === values.name
      );
      console.log(itemFromList, "item from list");
      if (itemFromList) {
        const itemAmount =
          parseFloat(values.quantity) * parseFloat(values.rate);
        const GstAmount =
          (parseFloat(itemFromList.gst.toString()) * itemAmount) / 100;
        const grossAmount = GstAmount + itemAmount;
        const mergedItem: Item = {
          ...itemFromList,
          slno: ItemListData.length + 1,
          quantity: parseFloat(values.quantity),
          Amount: itemAmount,
          quantityInBags: values.QtyInBags,
          rate: values.rate,
          GSTAmount: GstAmount,
          grossAmount: grossAmount,
        };

        console.log("mergedItem:", mergedItem);
        dispatch(addItem(mergedItem));
        message.success("Item Added Successfully");
        setIsModalVisible(false);
        modalForm.resetFields();
      } else {
        message.error("Item not found in master list");
      }
    });
  };

  const cancel = () => {
    message.error("Click on No");
  };

  const confirm = (record: Item) => {
    dispatch(deleteItem(record.id));
    message.success("Click on Yes");
  };

  const handleSelectItem = (record) => {
    console.log(record, "record inside select user");
    const { sk, userType, institutionSubType, user, ...restRecord } = record;
    setUserDetails(restRecord);
    setIsUserModalVisible(false);
  };

  const columnsOfItems = [
    { title: "SlNo", dataIndex: "slno", key: "slno" },
    { title: "Item Code", dataIndex: "Item_Code", key: "Item_Code" },
    { title: "Hsn Code", dataIndex: "hsncode", key: "hsncode" },
    { title: "Item Name", dataIndex: "name", key: "name" },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "UOM",
      dataIndex: "Uom",
      key: "Uom",
    },
    { title: "Amount", dataIndex: "Amount", key: "Amount" },
    { title: "GST %", dataIndex: "GST", key: "GST" },
    { title: "GST Amount", dataIndex: "GSTAmount", key: "GSTAmount" },
    { title: "Total", dataIndex: "grossAmount", key: "grossAmount" },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: Item) => (
        <Popconfirm
          title="Are you sure to delete this item?"
          onConfirm={() => confirm(record)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  const columsOfUsers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "UserType",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
    },
    {
      title: "Institution SubType",
      dataIndex: "institutionSubType",
      key: "institutionSubType",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleSelectItem(record)}>
          Select
        </Button>
      ),
    },
  ];

  const dataSource = ItemListData.map((item, index) => ({
    ...item,
    key: item.key,
    slno: index + 1,
    Item_Code: item.Item_Code,
    name: item.name,
  }));

  const totals = useMemo(() => {
    const totalQuantity = ItemListData.reduce(
      (sum, item) => sum + item.quantity,
      0
    ).toFixed(2);
    const totalGross = ItemListData.reduce(
      (sum, item) => sum + item.Amount,
      0
    ).toFixed(3);
    const totalGst = ItemListData.reduce(
      (sum, item) => sum + item.GSTAmount,
      0
    ).toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);

    // return { totalQuantity, totalGross, totalGst, totalAmount };
    return { totalQuantity, totalGross, totalGst, total };
  }, [ItemListData]);

  const handleSaveSupplierOrder = async (values: any) => {
    console.log(userDetails, "userDetails in save");
    console.log(ItemListData, "items in  save");
    const payLoad = {
      ...userDetails,
      ...values,
      ...totals,
      ItemListData: ItemListData,
    };
    console.log(payLoad, "payLoad inside supplierOrder");
    setPdfData({ payLoad });
    isSearch
      ? dispatch(saveUpdateInvoices(payLoad))
      : dispatch(saveSupplierOrder(payLoad, setUserSk));
    form.resetFields();
  };

  return (
    <div className="supplierOrder">
      <SearchDocComponent
        docType={"SUP"}
        docData={setUserDetails}
        setIsSearch={setIsSearch}
      />
      <Form form={form} onFinish={handleSaveSupplierOrder}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[
                {
                  required: true,
                  message: "Please input your Number",
                },
              ]}
            >
              <Input placeholder="No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "phone number must be 10 digits",
                },
              ]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select the date",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Reference"
              name="reference"
              rules={[
                {
                  required: true,
                  message: "Please input the Reference",
                },
                {
                  pattern: /^[a-zA-Z\0-9s]+$/,
                  message: "Please Enter Reference",
                },
              ]}
            >
              <Input placeholder="Reference" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Revised On"
              name="revisedOn"
              rules={[
                {
                  required: true,
                  message: "Please select the Revised on date",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Delivery Due By"
              name="deliveryDueBy"
              rules={[
                {
                  required: true,
                  message: "Please select the Delivery Due By date",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Order No"
              name="orderNo"
              rules={[
                {
                  required: true,
                  message: "Please enter the Order No",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Please Enter Number only",
                },
              ]}
            >
              <Input placeholder="Order Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="User Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input placeholder="Customer Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Search",
                },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: "Please Search By Name only",
                },
              ]}
            >
              <Input placeholder="Supplier Name" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Add Item
        </Button>
        <Button
          onClick={() => {
            setIsUserModalVisible(true);
          }}
        >
          Add User
        </Button>
        <Modal
          title="Select a User"
          open={isUserModalVisible}
          onCancel={handleMasterCancel}
          footer={null}
          width={800}
        >
          <Table
            dataSource={customersDetails}
            columns={columsOfUsers}
            rowKey="id"
            pagination={{ pageSize: 5 }}
            scroll={{ x: 1350 }}
          />
        </Modal>
        <Modal
          title="Add Item"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>
              <Button key="save" type="primary" onClick={handleModalFormSubmit}>
                Save
              </Button>
            </div>,
          ]}
          width={330}
        >
          <Form form={modalForm}>
            <Form.Item
              label="Item Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter the Item Name",
                },
              ]}
            >
              <Select placeholder="ItemName" style={{ width: 200 }}>
                {userMasterList.map((item) => (
                  <Option key={item.key} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Qty"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: "Please enter the Qty",
                },
                {
                  pattern: /^\d{1,5}(\.\d{1,4})?$/,
                  message:
                    "Please enter quantity upto 5 digits and 4 decimal places only",
                },
              ]}
            >
              <Input placeholder="Quantity" style={{ width: 150 }} />
            </Form.Item>
            <Form.Item
              label="Rate"
              name="rate"
              rules={[
                {
                  required: true,
                  message: "Please enter the Rate",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "please Enter the Rate",
                },
              ]}
            >
              <Input placeholder="Rate" style={{ width: 150 }} />
            </Form.Item>
            <Form.Item
              name="uom"
              label="UOM"
              rules={[
                {
                  required: true,
                  message: "Please select UOM",
                },
              ]}
            >
              <Select defaultValue="UOM" style={{ width: 120 }}>
                <Option value="kg">Kg</Option>
                <Option value="ton">Ton</Option>
                <Option value="quintal">Quintal</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Discount%"
              name="discount"
              initialValue={0}
              rules={[
                {
                  message: "Please enter the Discount",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Discount must be a valid percentage",
                },
              ]}
            >
              <Input placeholder="Discount" style={{ width: 150 }} />
            </Form.Item>
          </Form>
        </Modal>
        <Table
          columns={columnsOfItems}
          dataSource={dataSource}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: 1350 }}
        />
        <div className="totals">
          <strong>Total Quantity: {totals.totalQuantity} </strong>
          <strong>Total Gross: {totals.totalGross} </strong>
          <strong>Total Gst: {totals.totalGst} </strong>
          <strong>Total: {totals.total} </strong>
        </div>
        <Button htmlType="submit">Save</Button>
      </Form>
    </div>
  );
};
export default SupplierOrder;
