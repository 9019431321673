import React, { useEffect, useState, useMemo } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Modal,
  Table,
  message,
  Col,
  Row,
  Radio,
} from "antd";
import moment from "moment";
import SearchDocComponent from "../searchDocComponent";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state/store";
import {
  BASE_URL,
  fetchUserDetails,
  savePurchaseEntryData,
  saveUpdateInvoices,
} from "../../state/api";
import AddUser from "../../profile/addUser";
// import {
//   savePurchaseEntryData,
//   saveUpdateInvoices,
// } from "../../../../store/api";
// import { useDispatch } from "react-redux";
// import SearchComponent from "../searchComponent";
// import SearchDocComponent from "../searchDocComponent";

const { Option } = Select;

interface Item {
  itemName: string;
  quantity: number;
  rate: number;
  uom: string;
  discount: number;
  key: string;
}

const PurchaseEntry: React.FC = () => {
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSourceData, setDataSourceData] = useState<any>([]);
  const [modalData, setModalData] = useState<Item[]>([]);
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    PoDate: "",
    zip: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
  });
  const [userProfileData, setUserPorfileData] = useState({
    userCode: "",
    pincode: "",
    gstin: "",
    address: "",
  });
  const [isSearch, setIsSearch] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const orgId: string = localStorage.getItem("orgId")!;

  useEffect(() => {
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    console.log(userFormValues, "userFormValues inside useEffect");
    setDataSourceData(userFormValues.ItemListData);
  }, [isSearch]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleUserCancel = () => {
    setIsUserModalVisible(false);
  };
  const handleModalFormSubmit = (selectedItem: any) => {
    setDataSourceData(selectedItem.ItemListData);
    console.log(selectedItem.ItemListData, "selected Item");
    console.log(selectedItem, "selectedItem inside select");
    form.setFieldsValue({
      userCode: selectedItem.userCode,
      name: selectedItem.name,
      reference: selectedItem.Reference,
      supplierinvoiceno: selectedItem.SupplierInvoiceNo,
      supplierinvoicedate: selectedItem.supplierinvoicedate
        ? moment(selectedItem.supplierinvoicedate)
        : null,
    });
    setUserPorfileData({
      userCode: selectedItem.userCode,
      pincode: selectedItem.pincode,
      gstin: selectedItem.gstin,
      address: selectedItem.address,
    });
    setIsModalVisible(false);
    message.success("Item added successfully");
  };

  const columnsOfItems = [
    { title: "SlNo", dataIndex: "slno", key: "slno" },
    { title: "Item Code", dataIndex: "Item_Code", key: "Item_Code" },
    { title: "Hsn Code", dataIndex: "HSN_Code", key: "HSN_Code" },
    { title: "Item Name", dataIndex: "itemName", key: "itemName" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Rate", dataIndex: "rate", key: "rate" },
    { title: "HSN", dataIndex: "hsn", key: "hsn" },
    { title: "UOM", dataIndex: "uom", key: "uom" },
    { title: "Amount", dataIndex: "Amount", key: "Amount" },
    {
      title: "Discount%",
      dataIndex: "discountPercent",
      key: "discountPercent",
    },
    { title: "Discount", dataIndex: "discount", key: "discount" },
    { title: "GST %", dataIndex: "GST", key: "GST" },
    { title: "GST Amount", dataIndex: "GSTAmount", key: "GSTAmount" },
    { title: "Total", dataIndex: "grossAmount", key: "grossAmount" },
  ];

  const totals = useMemo(() => {
    const totalQuantity = dataSourceData
      .reduce((sum: any, item: { quantity: any }) => sum + item.quantity, 0)
      .toFixed(2);
    const totalGross = dataSourceData
      .reduce((sum: any, item: { Amount: any }) => sum + item.Amount, 0)
      .toFixed(3);
    const totalGst = dataSourceData
      .reduce((sum: any, item: { GSTAmount: any }) => sum + item.GSTAmount, 0)
      .toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);

    return { totalQuantity, totalGross, totalGst, total };
  }, [dataSourceData]);

  const columsOfUsers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "User code",
      dataIndex: "userCode",
      key: "userCode",
    },
    {
      title: "UserType",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
    },
    {
      title: "Institution SubType",
      dataIndex: "institutionSubType",
      key: "institutionSubType",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleModalFormSubmit(record)}>
          Select
        </Button>
      ),
    },
  ];

  const handleSave = async (values: any) => {
    console.log(values, "values inside form");
    console.log(modalData, "modalData inside save");
    try {
      const payload = {
        pk: orgId,
        ...userProfileData,
        ...values,
        ItemListData: dataSourceData,
      };
      console.log(payload, "payLoad inside pe");
      isSearch
        ? dispatch(saveUpdateInvoices(payload))
        : dispatch(savePurchaseEntryData(payload));
      message.success("Data saved successfully");
    } catch (error: any) {
      message.error("Failed to save data", error);
    }
  };

  const handleSelectGrn = async () => {
    setIsModalVisible(true);
    // const endPoint = `http://localhost:4000/dev/getGrn?orgId=${encodeURIComponent(
    //   orgId
    // )}`;
    const endPoint = `${BASE_URL}/dev/getGrn?orgId=${encodeURIComponent(
      orgId
    )}`;
    const userDetails = await fetchUserDetails(`${endPoint}`);
    console.log(userDetails, "userDetails");
    setModalData(userDetails);
  };

  console.log(modalData, "modalData");
  console.log(dataSourceData, "dataSource");

  return (
    <div className="purchaseEntry">
      <SearchDocComponent
        docType={"PO"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form
        form={form}
        layout="horizontal"
        initialValues={userFormValues}
        onFinish={handleSave}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[
                {
                  required: true,
                  message: "Please input the No",
                },
              ]}
            >
              <Input placeholder="No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select the date",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input disabled placeholder="Customer Code" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Supplier Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please  Search by name",
                },
              ]}
            >
              <Input placeholder="Supplier Name" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Reference"
              name="reference"
              rules={[
                {
                  required: true,
                  message: "Please input the Reference",
                },
              ]}
            >
              <Input disabled placeholder="Reference" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice No"
              name="supplierinvoiceno"
              rules={[
                {
                  required: true,
                  message: "Please Enter Supplier Invoice No",
                },
              ]}
            >
              <Input disabled placeholder="Supplier Invoice No" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {/* <Col span={8}>
            <SearchComponent
              setUserFormValues={() => {}}
              setUserDetails={setModalData}
              compType={"po"}
            />
          </Col> */}
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice Date"
              name="supplierinvoicedate"
              rules={[
                {
                  required: true,
                  message: "Please Enter Invoice Date",
                },
              ]}
            >
              <DatePicker disabled placeholder="Supplier Invoice Date" />
            </Form.Item>
          </Col>
        </Row>

        <Button
          id="purchaseentry-button1"
          type="primary"
          className="ant-btn-primary"
          onClick={handleSelectGrn}
        >
          Select GRN
        </Button>
        <Button
          onClick={() => {
            setIsUserModalVisible(true);
          }}
        >
          Add User
        </Button>
        <AddUser
          isAddUser={isUserModalVisible}
          setAddUser={setIsUserModalVisible}
        />
        {/* <Button
          id="purchaseentry-button2"
          type="primary"
          className="ant-btn-primary"
          htmlType="submit"
          style={{ marginLeft: "10px" }}
        >
          Save
        </Button>
      </Form> */}
        <div className="modal-wrapper">
          <Modal
            title="GRN Details of this Supplier"
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <div className="table-wrapper">
              <Table
                columns={columsOfUsers}
                dataSource={modalData}
                pagination={{ pageSize: 8, position: ["bottomLeft"] }}
                scroll={{ x: "max-content" }} // Responsive width
              />
            </div>
          </Modal>
        </div>
        <Table
          columns={columnsOfItems}
          dataSource={dataSourceData}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: 1350 }}
        />
        <div className="purchaseentry-total">
          <div className="total-item">
            <strong>Total Quantity: {totals.totalQuantity}</strong>
          </div>
          <div className="total-item">
            <strong>Total Gross: {totals.totalGross}</strong>
          </div>
          <div className="total-item">
            <strong>Total GST: {totals.totalGst}</strong>
          </div>
          <div className="total-item">
            <strong>Total: {totals.total}</strong>
          </div>
        </div>
        <Button
          id="purchaseentry-button2"
          type="primary"
          className="ant-btn-primary"
          htmlType="submit"
          style={{ marginLeft: "10px" }}
        >
          Save
        </Button>
      </Form>
    </div>
  );
};
export default PurchaseEntry;
