import React from "react";
import { Layout, Button } from "antd";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router-dom";
const { Header } = Layout;
import './header.scss';
const DashboardHeader: React.FC = () => {
  const { signOut } = useAuthenticator();
  const navigate = useNavigate();
  const handleSignOut = async () => {
    try {
      signOut();
      navigate("/");
      localStorage.clear()
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };
  return (
    <Header className="header">
      <div className="header-content">
        <Button onClick={handleSignOut} className="sign-out">
          Sign Out
        </Button>
      </div>
    </Header>
  );
};
export default DashboardHeader;
