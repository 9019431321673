import React, { useEffect, useState, useMemo } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Table,
  Popconfirm,
  Modal,
  message,
  PopconfirmProps,
  Row,
  Col,
  Radio,
} from "antd";
import SearchDocComponent from "../searchDocComponent";
import { addItem, removeAllItems } from "../../state/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { savePurchaseReturn, saveUpdateInvoices } from "../../state/api";
import { handlePurchaseReturnPdf } from "./generatePurchaseReturnPdf";
// import SearchComponent from "../searchComponent";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   addItem,
//   deleteItem,
//   removeAllItems,
// } from "../../../../store/slices/mainSlice";
// import {
//   getMasterListData,
//   savePurchaseReturn,
//   saveUpdateInvoices,
// } from "../../../../store/api";
// import { RootState } from "../../../../store";
// import { handlePurchaseReturnPdf } from "./generatePurchaseReturnPdf";
// import SearchDocComponent from "../searchDocComponent";

const { Option } = Select;

interface ItemType {
  key: string;
  name: string;
  category: string;
  variety: string;
  grade: string;
  gst: string;
  hsncode: string;
  uom: string;
}

const PurchaseReturn = () => {
  const [form] = Form.useForm(); // Use form instance
  const [itemName, setItemName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uomValue, setUomValue] = useState("");
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [showPrintPdf, setShowPrintPdf] = useState(false);
  const [pdfData, setPdfData] = useState<any>();
  const [showAddItem, setShowAddItem] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [formTwo] = Form.useForm();
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    zip: "",
    gstin: "",
    customerCode: "",
    ItemListData: [],
  });

  const dispatch = useDispatch<AppDispatch>();
  const customersDetails = useSelector(
    (state: RootState) => state.main.userProfileDetails.customers
  );
  const ItemListData = useSelector((state: RootState) => state.main.itemList);
  const userMasterList = useSelector(
    (state: RootState) => state.main.userMasterListData
  );
  //   const masterListData = useSelector(
  //     (state: RootState) => state.main.masterListData
  //   );
  //   const BASE_URL = process.env.REACT_APP_BASE_URL;
  //   const STAGE = process.env.REACT_APP_ENV;

  useEffect(() => {
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    if (isSearch) {
      userFormValues.ItemListData.forEach(
        (item: any) =>
          !ItemListData.some((existingItem) => existingItem.key === item.key) &&
          dispatch(addItem(item))
      );
    }
  }, [isSearch]);

  //   useEffect(() => {
  //     let myVar = true;
  //     if (myVar) {
  //       dispatch(getMasterListData());
  //     }
  //     return () => {
  //       myVar = false;
  //     };
  //   }, [dispatch]);

  const addItemArray = [
    {
      name: "name",
      required: true,
      label: "Item Name",
      isDropdown: true,
    },
    {
      name: "quantity",
      required: true,
      label: "Quantity",
      rules: [
        {
          required: true,
          message: "Please input Quantity!",
        },
        {
          pattern: /^\d{1,2}(\.\d{1,2})?$/,
          message: "Please enter quantity up to 2 digits only",
        },
      ],
    },
    {
      name: "QtyInBags",
      required: true,
      label: "Qty In Bags",
    },
    {
      name: "rate",
      required: true,
      label: "Rate",
      rules: [
        {
          required: true,
          message: "Please input Rate!",
        },
        {
          pattern: /^\d{1,5}(\.\d{1,3})?$/,
          message: "Please enter the rate up to 5 digits only",
        },
      ],
    },
  ];

  const cancel: PopconfirmProps["onCancel"] = (e: any) => {
    console.log(e);
    message.error("Click on No");
  };

  const confirm: PopconfirmProps["onConfirm"] = (record: any) => {
    // dispatch(deleteItem(record.id));
    message.success("Click on Yes");
  };

  const handleSelectItem = (record) => {
    console.log(record, "record inside select user");
    const { sk, userType, institutionSubType, user, ...restRecord } = record;
    setUserFormValues(restRecord);
    setIsUserModalVisible(false);
  };

  const columsOfUsers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "UserType",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
    },
    {
      title: "Institution SubType",
      dataIndex: "institutionSubType",
      key: "institutionSubType",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleSelectItem(record)}>
          Select
        </Button>
      ),
    },
  ];

  const columnsOfItems = [
    {
      title: "SL.No",
      dataIndex: "slno",
      key: "slno",
    },
    {
      title: "Item code",
      dataIndex: "Item_Code",
      key: "Item_Code",
    },
    { title: "Hsn Code", dataIndex: "hsncode", key: "hsncode" },
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      // render: (text: any, record: any) => renderInput(text, record, "quantity"),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      // render: (text: any, record: any) => renderInput(text, record, "rate"),
    },
    {
      title: "UOM",
      dataIndex: "Uom",
      key: "Uom",
      // render: (text: any, record: any) => renderInput(text, record, "Uom"),
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "Disc%",
      dataIndex: "Disc%",
      key: "Disc%",
    },
    {
      title: "Discount",
      dataIndex: "Discount",
      key: "Discount",
    },
    {
      title: "GST%",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "GSTAmount",
      dataIndex: "GSTAmount",
      key: "GSTAmount",
    },
    {
      title: "Total",
      dataIndex: "grossAmount",
      key: "grossAmount",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <Popconfirm
          title="Are you sure to delete this item?"
          onConfirm={() => confirm(record)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  const totals = useMemo(() => {
    const totalQuantity = ItemListData.reduce(
      (sum, item) => sum + item.quantity,
      0
    ).toFixed(2);
    const totalGross = ItemListData.reduce(
      (sum, item) => sum + item.Amount,
      0
    ).toFixed(3);
    const totalGst = ItemListData.reduce(
      (sum, item) => sum + item.GSTAmount,
      0
    ).toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);

    // return { totalQuantity, totalGross, totalGst, totalAmount };
    return { totalQuantity, totalGross, totalGst, total };
  }, [ItemListData]);

  console.log(userFormValues, "userFormValues");
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setUomValue(value);
  };

  const handleSaveForm2 = (values: any) => {
    //add item and save function
    console.log("values", values);
    const itemFromList: any = userMasterList.find(
      (item: ItemType) => item.name === values.name
    );
    console.log(itemFromList, "itemFromList");
    const itemAmount = values.quantity * parseFloat(values.rate);
    const GstAmount = (parseFloat(itemFromList?.gst) * itemAmount) / 100;
    const grossAmount = GstAmount + itemAmount;
    if (itemFromList) {
      const mergedItem = {
        ...itemFromList,
        slno: ItemListData.length + 1,
        quantity: parseFloat(values.quantity),
        Amount: itemAmount,
        quantityInBags: values.QtyInBags,
        rate: values.rate,
        GSTAmount: GstAmount,
        grossAmount: grossAmount,
      };
      console.log(mergedItem, "mergedItem");
      dispatch(addItem(mergedItem));
      setShowAddItem(true);
      message.success("Item Added Successfully");
      formTwo.resetFields();
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    formTwo.resetFields();
    setIsModalVisible(false);
  };

  const handleMasterCancel = () => {
    setIsUserModalVisible(false);
  };

  const saveAllDetails = async (values: any) => {
    console.log(values, "values inside save");
    const payLoad = {
      ...userFormValues,
      ItemListData,
      ...values,
    };
    form.resetFields();
    console.log(payLoad, "payLoadData");
    setPdfData({ totals, ...payLoad });
    isSearch
      ? dispatch(saveUpdateInvoices(payLoad))
      : dispatch(savePurchaseReturn(payLoad, setPdfData));
    console.log("im here after save");
    setShowPrintPdf(true);
    dispatch(removeAllItems());
    setIsSearch(false);
  };

  const printPdf = () => {
    console.log("im here item", pdfData);
    console.log("im here user", userFormValues);
    handlePurchaseReturnPdf(pdfData);
    form.resetFields();
  };

  console.log(pdfData, "inside purchase return");

  return (
    <div className="purchaseReturn">
      <SearchDocComponent
        docType="PR"
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form
        form={form}
        initialValues={userFormValues}
        onFinish={saveAllDetails}
      >
        {/* First row - 3 columns */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the No" }]}
            >
              <Input placeholder="No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true, message: "Please select the date" }]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Code"
              name="userCode"
              rules={[{ required: true, message: "Please input the code" }]}
            >
              <Input placeholder="Customer Code" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Reference"
              name="reference"
              rules={[
                { required: true, message: "Please input the Reference" },
                {
                  pattern: /^[a-zA-Z\0-9s]+$/,
                  message: "Please Enter Reference",
                },
              ]}
            >
              <Input placeholder="Reference" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice No"
              name="supplierinvoiceno"
              rules={[
                { required: true, message: "Please enter Supplier Invoice No" },
              ]}
            >
              <Input placeholder="Supplier Invoice No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice Date"
              name="supplierinvoicedate"
              rules={[{ required: true, message: "Please enter Invoice Date" }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={8}>
            {/* <SearchComponent
              setUserFormValues={setUserFormValues}
              setUserDetails={() => {}}
              compType={"pr"}
            /> */}
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input the phone number",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Please Enter 10 digit number only",
                },
              ]}
            >
              <Input
                placeholder="Enter phone number"
                // onChange={handleInputChange}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Name"
              name="name"
              rules={[{ required: true, message: "Please input the code" }]}
            >
              <Input placeholder="Supplier Name" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Button onClick={() => setIsModalVisible(true)} type="primary">
          Add Item
        </Button>
        <Button
          onClick={() => {
            setIsUserModalVisible(true);
          }}
        >
          Add User
        </Button>
        <Modal
          title="Select a User"
          open={isUserModalVisible}
          onCancel={handleMasterCancel}
          footer={null}
          width={800}
        >
          <Table
            dataSource={customersDetails}
            columns={columsOfUsers}
            rowKey="id"
            pagination={{ pageSize: 5 }}
            scroll={{ x: 1350 }}
          />
        </Modal>
        {/* <Button htmlType="submit" type="default">
          Save
        </Button> */}
        <Modal
          title="Add Item"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          className="modalContainer"
          width={300}
        >
          <Form
            form={formTwo}
            onFinish={handleSaveForm2}
            className="modalContainer"
          >
            {addItemArray.map((item) => (
              <Form.Item
                key={item.name}
                name={item.name}
                label={item.label}
                rules={item.rules}
              >
                {item.isDropdown ? (
                  <Select
                    placeholder={`Select ${item.label}`}
                    style={{ width: 150 }}
                  >
                    {userMasterList.map((item: ItemType) => (
                      <Option
                        key={item.key}
                        value={item.name}
                        scroll={{ x: "max-content" }}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    className="customInput"
                    placeholder={`Enter ${item.label}`}
                    id="modalInput"
                    style={{ width: 150 }}
                  />
                )}
              </Form.Item>
            ))}
            <Form.Item
              name="uom"
              label="UOM"
              rules={[{ required: true, message: "Please input UOM!" }]}
            >
              <Select
                defaultValue="Select UOM"
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: "kg", label: "Kg" },
                  { value: "ton", label: "Ton" },
                  { value: "quintal", label: "Quintal" },
                ]}
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button type="primary" htmlType="submit">
                Save Item
              </Button>
            </div>
          </Form>
        </Modal>
        <Table
          columns={columnsOfItems}
          dataSource={ItemListData}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: 1350 }}
        />
        <div className="totals">
          <strong>Total Quantity: {totals.totalQuantity}</strong>
          <strong>Total Gross: {totals.totalGross}</strong>
          <strong>Total GST: {totals.totalGst}</strong>
          <strong>Total: {totals.total}</strong>
        </div>
        <Button htmlType="submit" type="default">
          Save
        </Button>
        <Button disabled={!showPrintPdf} onClick={printPdf} type="primary">
          Download PDF
        </Button>
      </Form>
    </div>
  );
};
export default PurchaseReturn;
